import React from "react"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"
import Submenutreneri from "../../components/submenutreneri/submenutreneri"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

import "./../treneri.scss"

import ImgTreneriRezler from "../../images/treneri/zdenek-rezler.jpg"

const ZdenekRezler = () => (

	<div>

		<Layout>
			<Seo title="Zdeněk Rezler" description="Zdeněk Rezler" />

			<div className="stk-container stk-container--top-margin">

				<Row>
					<Col xs="12" xl="9">
						<div class="stk-trener__header">
							<div>
								<img class="stk-trener__image" src={ImgTreneriRezler} alt="Zdeněk Rezler" />
							</div>
							<div>
								<h1 className="stk-h1">Zdeněk Rezler</h1>
								<p>Trenér - latinsko-americké tance</p>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--facebook" href="https://www.facebook.com/zdenek.rezler" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faFacebookF} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--instagram" href="https://www.instagram.com/zdenekrezler/" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faInstagram} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--phone" href="tel:+420739039192" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faPhone} />
								</a>
							</div>
						</div>
						<ul>
							<li>Vícemistr ČR v&nbsp;latinsko-amerických tancích v&nbsp;roce 2002</li>
							<li>Finalista MČR latina dospělí v&nbsp;letech 1998, 1999, 2000, 2002, 2008</li>
							<li>Vítěz MČR latina 2014 v&nbsp;kategorii senior-I a&nbsp;Prague Open 2014 latina v&nbsp;kategorii senior-I</li>
						</ul>
					</Col>
					<Col xs="12" xl="3">
						<Submenutreneri></Submenutreneri>
					</Col>
				</Row>
			</div>
		</Layout>

	</div>

)

export default ZdenekRezler